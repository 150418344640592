import React from "react";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  ThemeProvider,
  createTheme,
  Grid2,
  Container,
  Link,
} from "@mui/material";
import CouponView from "./CouponView";
import { EgnaraderLogo } from "./images/logo";
import plus18 from "./images/plus18.png";
import spelinsp from "./images/spelinspektionen.png";

// Create a custom theme
const theme = createTheme();


export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        {/* AppBar */}
        <AppBar
          position="fixed"
          sx={{ backgroundColor: "#BBBBBB" }}
        >
          <Toolbar>
            {EgnaraderLogo()}
            <Typography variant="h6" noWrap component="div">

            </Typography>
          </Toolbar>

        </AppBar>
        {/* Main Content */}
        <Toolbar />
        <Container maxWidth="lg">
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}
          >
            <Toolbar />
            <Grid2 container direction="column">
              <Typography sx={{ fontWeight: "bold" }}>Välkommen, här kan du göra egna rader på PoolX.</Typography>
            </Grid2>
            <CouponView />
            <Grid2 container direction="column" sx={{ marginTop: "40px" }}>
              <Typography sx={{ fontWeight: "bold" }}>Du kan spela PoolX hos dessa operatörer:</Typography>
              <Link href="https://trk.affiliates.dbet.com/o/h2a9KC?lpage=vWZQ4d&site_id=7011624" target="_blank">
                <Typography sx={{ marginTop: "20px" }}>DBet - Supertipset</Typography>
              </Link>
              <Link href="https://ntrfr.expekt.se/redirect.aspx?pid=3746939&bid=20788&redirectURL=https://www.expekt.se/sports#pool-betting" target="_blank">
                <Typography sx={{ marginTop: "20px" }}>Expekt - Expekttipset</Typography>
              </Link>
            </Grid2>

            <Grid2 container direction="column" sx={{ marginTop: "40px" }}>
              <Typography sx={{ fontWeight: "bold" }}>Lämna in ditt spel så här:</Typography>
              <Typography sx={{ marginTop: "20px" }}>Gå in på en omgång</Typography>
              <Typography sx={{ marginTop: "20px" }}>Välj "Info"</Typography>
              <Typography sx={{ marginTop: "20px" }}>Välj "Filuppladdning"</Typography>
            </Grid2>


            <Grid2 container direction="column" justifyContent="center" alignItems="center" sx={{ marginTop: "100px" }}>
              <Typography>Spela ansvarsfullt  - Behöver du hjälp? Kontakta <a href="https://stodlinjen.se/">Stödlinjen</a></Typography>
              <Grid2 container justifyContent="center" alignItems="center">
                <Grid2 container alignItems="center" sx={{ margin: "20px" }}>
                  <img alt="Plus 18" src={plus18} height="20px"></img>
                </Grid2>
                <Grid2 container alignItems="center" sx={{ margin: "20px" }}>
                  <a href="https://www.spelinspektionen.se/">
                    <img alt="Spelinspektionen" src={spelinsp} height="40px"></img>
                  </a>
                </Grid2>
              </Grid2>
            </Grid2>

          </Box>
        </Container>

      </div>
    </ThemeProvider >
  );
};


